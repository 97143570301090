<template>
  <svg :height="`${height}px` || '20px'" :width="`${width}px` || '20px'" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M80.9092 11.1406H21.8747C21.6984 11.1406 21.5238 11.1754 21.3609 11.243C21.198 11.3106 21.0501 11.4096 20.9256 11.5345C20.801 11.6593 20.7024 11.8075 20.6352 11.9706C20.5681 12.1336 20.5337 12.3083 20.5342 12.4846V51.3906C20.5337 51.567 20.5681 51.7417 20.6352 51.9047C20.7024 52.0677 20.801 52.2159 20.9256 52.3408C21.0501 52.4656 21.198 52.5647 21.3609 52.6323C21.5238 52.6998 21.6984 52.7346 21.8747 52.7346H58.0997V64.8096H52.7342V67.4906H74.1997V70.1751H52.7342V72.8596H75.5402C75.8966 72.8596 76.2385 72.718 76.4905 72.466C76.7426 72.2139 76.8842 71.8721 76.8842 71.5156V52.7346H80.9092C81.265 52.7337 81.606 52.5917 81.8573 52.3397C82.1086 52.0878 82.2497 51.7465 82.2497 51.3906V12.4846C82.2497 12.1288 82.1086 11.7875 81.8573 11.5355C81.606 11.2836 81.265 11.1416 80.9092 11.1406ZM79.5652 13.8251V19.1906H23.2152V13.8251H79.5652ZM60.7842 52.7346H63.4652V55.4156C63.4559 55.5977 63.4837 55.7797 63.547 55.9506C63.6102 56.1216 63.7076 56.2779 63.8331 56.41C63.9587 56.5421 64.1098 56.6473 64.2773 56.7192C64.4448 56.7911 64.6252 56.8282 64.8074 56.8282C64.9897 56.8282 65.1701 56.7911 65.3376 56.7192C65.5051 56.6473 65.6562 56.5421 65.7817 56.41C65.9073 56.2779 66.0046 56.1216 66.0679 55.9506C66.1312 55.7797 66.159 55.5977 66.1497 55.4156V52.7346H68.8342V64.8096H60.7842V52.7346ZM74.1997 64.8096H71.5152V52.7346H74.1997V64.8096ZM23.2152 50.0501V21.8751H79.5652V50.0501H23.2152Z"
      :fill="`${color}`"
    />
    <path
      d="M8.4595 72.8594H43.3405C45.1191 72.8567 46.8241 72.1489 48.0818 70.8912C49.3394 69.6335 50.0472 67.9286 50.05 66.1499C50.05 65.7944 49.9088 65.4534 49.6574 65.2021C49.406 64.9507 49.065 64.8094 48.7095 64.8094H43.3405V55.4154H40.6595V64.8094H11.1405V41.9999H17.85V39.3154H9.8C9.62367 39.3154 9.44907 39.3502 9.2862 39.4178C9.12334 39.4854 8.97541 39.5844 8.85088 39.7093C8.72636 39.8341 8.62769 39.9823 8.56053 40.1454C8.49337 40.3084 8.45904 40.4831 8.4595 40.6594V64.8094H3.0905C2.73498 64.8094 2.39402 64.9507 2.14262 65.2021C1.89123 65.4534 1.75 65.7944 1.75 66.1499C1.75278 67.9286 2.46056 69.6335 3.71823 70.8912C4.97591 72.1489 6.68088 72.8567 8.4595 72.8594ZM21.3185 67.4904L22.267 68.4389C22.5185 68.6909 22.8595 68.8331 23.2155 68.8344H28.5845C28.9405 68.8331 29.2815 68.6909 29.533 68.4389L30.4815 67.4904H47.138C46.8593 68.275 46.3447 68.9541 45.6648 69.4347C44.985 69.9153 44.1731 70.1739 43.3405 70.1749H8.4595C7.62691 70.1739 6.81504 69.9153 6.13517 69.4347C5.4553 68.9541 4.94073 68.275 4.662 67.4904H21.3185Z"
      :fill="`${color}`"
    />
    <path
      d="M25.9001 17.8499C26.6404 17.8499 27.2406 17.2498 27.2406 16.5094C27.2406 15.7691 26.6404 15.1689 25.9001 15.1689C25.1597 15.1689 24.5596 15.7691 24.5596 16.5094C24.5596 17.2498 25.1597 17.8499 25.9001 17.8499Z"
      :fill="`${color}`"
    />
    <path
      d="M29.9255 17.8499C30.6658 17.8499 31.266 17.2498 31.266 16.5094C31.266 15.7691 30.6658 15.1689 29.9255 15.1689C29.1851 15.1689 28.585 15.7691 28.585 16.5094C28.585 17.2498 29.1851 17.8499 29.9255 17.8499Z"
      :fill="`${color}`"
    />
    <path
      d="M33.9499 17.8499C34.6902 17.8499 35.2904 17.2498 35.2904 16.5094C35.2904 15.7691 34.6902 15.1689 33.9499 15.1689C33.2095 15.1689 32.6094 15.7691 32.6094 16.5094C32.6094 17.2498 33.2095 17.8499 33.9499 17.8499Z"
      :fill="`${color}`"
    />
    <path d="M25.9004 24.5596H29.9254V27.2406H25.9004V24.5596Z" :fill="`${color}`" />
    <path d="M33.9502 24.5596H43.3407V27.2406H33.9502V24.5596Z" :fill="`${color}`" />
    <path d="M46.0254 24.5596H50.0504V27.2406H46.0254V24.5596Z" :fill="`${color}`" />
    <path d="M25.9004 31.2656H29.9254V33.9501H25.9004V31.2656Z" :fill="`${color}`" />
    <path d="M33.9502 31.2656H39.3157V33.9501H33.9502V31.2656Z" :fill="`${color}`" />
    <path d="M42 31.2656H50.05V33.9501H42V31.2656Z" :fill="`${color}`" />
    <path d="M25.9004 37.9746H29.9254V40.6591H25.9004V37.9746Z" :fill="`${color}`" />
    <path d="M33.9502 37.9746H50.0502V40.6591H33.9502V37.9746Z" :fill="`${color}`" />
    <path d="M25.9004 44.6846H29.9254V47.3656H25.9004V44.6846Z" :fill="`${color}`" />
    <path d="M33.9502 44.6846H39.3157V47.3656H33.9502V44.6846Z" :fill="`${color}`" />
    <path d="M42 44.6846H50.05V47.3656H42V44.6846Z" :fill="`${color}`" />
    <path
      d="M58.492 30.3174L54.467 34.3424C54.2161 34.5943 54.0752 34.9353 54.0752 35.2909C54.0752 35.6464 54.2161 35.9875 54.467 36.2394L58.492 40.2644L60.389 38.3674L57.3125 35.2909L60.389 32.2004L58.492 30.3174Z"
      :fill="`${color}`"
    />
    <path
      d="M71.1236 30.3174L69.2266 32.2004L72.3031 35.2769L69.2266 38.3534L71.1236 40.2504L75.1486 36.2254C75.2732 36.1009 75.3721 35.953 75.4396 35.7903C75.5071 35.6275 75.5418 35.4531 75.5418 35.2769C75.5418 35.1007 75.5071 34.9262 75.4396 34.7635C75.3721 34.6007 75.2732 34.4529 75.1486 34.3284L71.1236 30.3174Z"
      :fill="`${color}`"
    />
    <path d="M60.8506 42.893L66.2126 26.793L68.7606 27.6505L63.3986 43.7505L60.8506 42.893Z" :fill="`${color}`" />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '84'
    },
    height: {
      type: String,
      default: '84'
    }
  }
}
</script>
